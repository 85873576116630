var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ManagementClientsIndex', {
    attrs: {
      "clients": _vm.clients,
      "export-url": _vm.exportUrl,
      "filters": _vm.statuses,
      "role": _vm.authRole,
      "is-loading": _vm.isLoadingClients
    },
    on: {
      "update:persize": _vm.onChangePagesize,
      "update:sort": _vm.onChangeSort
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }