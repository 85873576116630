var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-menu', {
    attrs: {
      "close-on-select": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "background-color": "white",
      "color": "gray.900",
      "p": "0"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "30px",
      "height": "30px",
      "color": "#008C81"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-tabler-filter.svg'),
      "height": "100%",
      "width": "100%"
    }
  })], 1)], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "150px",
      "p": "0",
      "placement": "left-start"
    }
  }, [_c('c-menu-option-group', {
    attrs: {
      "default-value": "all",
      "type": "radio"
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change', $event);
      }
    }
  }, _vm._l(_vm.filters, function (item) {
    return _c('c-menu-item-option', {
      key: item.value,
      attrs: {
        "value": item.value,
        "border-bottom": "1px solid #F2F2F2",
        "font-size": "16px",
        "py": "12px",
        "pr": "6px"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }