<template>
  <c-menu
    :close-on-select="true"
  >
    <c-menu-button
      background-color="white"
      color="gray.900"
      p="0"
    >
      <c-box
        width="30px"
        height="30px"
        color="#008C81"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-tabler-filter.svg')"
          height="100%"
          width="100%"
        />
      </c-box>
    </c-menu-button>
    <c-menu-list
      z-index="10"
      min-width="150px"
      p="0"
      placement="left-start"
    >
      <c-menu-option-group
        default-value="all"
        type="radio"
        @change="$emit('change', $event)"
      >
        <c-menu-item-option
          v-for="item in filters"
          :key="item.value"
          :value="item.value"
          border-bottom="1px solid #F2F2F2"
          font-size="16px"
          py="12px"
          pr="6px"
        >
          {{ item.label }}
        </c-menu-item-option>
      </c-menu-option-group>
    </c-menu-list>
  </c-menu>
</template>

<script>
export default {
  name: 'ManagementClientFilterBtn',
  props: ['filters'],
}
</script>
