<template>
  <c-box
    width="fit-content"
  >
    <BaseChip
      :label="getLabel"
      :color="getColor"
      :screen="screen"
      :right-svg-icon="getIcon"
      right-svg-icon-height-and-width="20px"
      style="background-color: white;"
    />
  </c-box>
</template>

<script>
import BaseChip from '@/components/elements/base-chip.vue'

export default {
  name: 'ChipStatusProgram',
  components: {
    BaseChip,
  },
  props: {
    status: {
      type: String,
      default: 'active',
      validator(value) {
        return [
          'active',
          'non_active',
          'pending',
        ].includes(value)
      },
    },
    screen: {
      type: String,
      default: 'both',
      validator(value) {
        return [
          'mobile-only',
          'desktop-only',
          'both',
        ].includes(value)
      },
    },
  },
  computed: {
    getColor() {
      switch (this.status) {
        case 'active':
          return 'info'
        case 'pending':
          return 'warning'
        case 'non_active':
          return 'danger'
        default:
          return 'primary'
      }
    },
    getLabel() {
      switch (this.status) {
        case 'active':
          return 'Aktif'
        case 'pending':
          return 'Pending'
        case 'non_active':
          return 'Tidak Aktif'
        default:
          return 'Berhasil'
      }
    },
    getIcon() {
      switch (this.status) {
        case 'active':
          return require('@/assets/icons/icon-circle-check.svg')
        case 'pending':
          return require('@/assets/icons/icon-clock.svg')
        case 'non_active':
          return require('@/assets/icons/icon-circle-warn.svg')
        default:
          return require('@/assets/icons/icon-circle-check.svg')
      }
    },
  },
}
</script>
